<template>
  <b-container fluid>
    <div>
      <b-overlay :show="loading">
        <!-- <b-row>
          <b-col>
              <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="circular.org_id">
                  <div class="titleDiv">
                    <span class="text-center text-dark reportTitle" style="font-weight:bold;">{{ $t('externalTraining.apply_details') }}</span><br>
                  </div>
              </list-report-head>
          </b-col>
        </b-row> -->
        <b-col lg="12">
          <b-row>
            <b-col lg="12" sm="12" class="p-0">
                <b-table-simple bordered v-if="circular">
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('externalTraining.circular_memo_no') }}</b-th>
                      <b-td style="width:65%%;" class="text-left">{{ item.circular_memo_no }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_config.fiscal_year') }}</b-th>
                      <b-td style="width:65%%;" class="text-left bg-light">{{ ($i18n.locale=='bn') ? item.fiscal_year_bn : item.fiscal_year }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('globalTrans.organization') }}</b-th>
                      <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? item.org_bn : item.org }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_config.training_type') }}</b-th>
                      <b-td style="width:65%%;" class="text-left bg-light">{{ ($i18n.locale=='bn') ? item.training_type_bn : item.training_type }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_config.training_title') }}</b-th>
                      <b-td style="width:65%%;" class="text-left bg-light">{{ ($i18n.locale=='bn') ? item.training_title_bn : item.training_title }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_config.batch') }}</b-th>
                      <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? item.batch_name_bn : item.batch_name }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_tim.assign_for') }}</b-th>
                      <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? item.user_type_bn : item.user_type }}</b-td>
                    </b-tr>
                </b-table-simple>
                <b-table-simple bordered v-if="circular.length">
                  <thead>
                    <tr>
                      <th style="width:20%">{{ $t('globalTrans.sl_no') }}</th>
                      <th style="width:30%">{{ $t('globalTrans.name') }} {{ $t('globalTrans.name') }}</th>
                      <th style="width:30%">{{ $t('globalTrans.mobile') }}</th>
                      <th style="width:30%">{{ $t('globalTrans.designation') }}</th>
                    </tr>
                  </thead>
                  <b-tbody>
                    <b-tr v-for="(item, index) in circular" :key="index">
                      <b-td>
                        <slot>{{ $n(index + 1) }}</slot>
                      </b-td>
                      <b-td>
                        {{ ($i18n.locale=='bn') ? item.name_bn : item.name }}
                      </b-td>
                      <b-td>
                        {{ item.mobile | mobileNumber }}
                      </b-td>
                      <b-td>
                        {{ ($i18n.locale=='bn') ? item.designation_bn : item.designation_en }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
            </b-col>
          </b-row>
        </b-col>
      </b-overlay>
    </div>
  </b-container>
</template>
<style scoped>
.table-form th, td{
  padding: 3px !important;
  margin: 1px !important;
  vertical-align: middle !important;
  font-size: 15px;
}
</style>
<script>
// import ExportPdf from './export-pdf_details'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingApplicationBatchShow } from '../../api/routes'
// import ListReportHead from '@/components/custom/ExternalListReportHead.vue'

export default {
    components: {
        // ListReportHead
    },
    name: 'FormLayout',
    props: ['item'],
    created () {
      this.appliedListData()
    },
    data () {
      return {
        loading: false,
        circular: [],
        fiscal_year: '',
        fiscal_year_bn: '',
        org: '',
        org_bn: '',
        training_title: '',
        training_title_bn: '',
        training_type: '',
        training_type_bn: '',
        slOffset: 1,
        trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
      }
    },
    computed: {
      orgTypeList: function () {
        return [
          {
            value: 1,
            text: this.$i18n.locale === 'bn' ? 'এমওসি' : 'MOC',
            text_en: 'MOC',
            text_bn: 'এমওসি'
          },
          {
            value: 2,
            text: this.$i18n.locale === 'bn' ? 'ব্যক্তিগত' : 'Private',
            text_en: 'Private',
            text_bn: 'ব্যক্তিগত'
          },
          {
            value: 3,
            text: this.$i18n.locale === 'bn' ? 'অন্যান্য' : 'Others',
            text_en: 'Others',
            text_bn: 'অন্যান্য'
          }
        ]
      }
    },
    methods: {
      async appliedListData () {
        this.loading = true
        const params = {
          circular_memo_no: this.item.circular_memo_no,
          batch_id: this.item.batch_id,
          registration_for: this.item.registration_for
        }
        RestApi.getData(trainingElearningServiceBaseUrl, trainingApplicationBatchShow, params).then(response => {
          if (response.success) {
            this.circular = response.data
            this.loading = false
          } else {
            this.loading = false
            this.circular = []
          }
        })
      },
      getOrgType (orgType) {
        const orgId = this.orgTypeList.find(obj => obj.value === orgType)
        return orgId.text
      }
    }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  .reportTitle {
        font-weight: bold;
        border: 1px solid;
        padding: 8px 15px 8px 15px;
        border-radius: 11px;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
